<template>
  <div>
    <el-form :model="form"
             :rules="rules"
             class="form_warp"
             ref="formRef"
             label-width="70px">
      <el-row :gutter="10">
        <el-col :span="4">
          <el-form-item label="科目:"
                        prop="subject_id">
            <el-select v-model="form.subject_id"
                       style="width:100%"
                       @change="getSourceList(0)"
                       placeholder="请选择">
              <el-option v-for="item in subjectList"
                         :key="item.subject_id"
                         :label="item.subject_name"
                         :value="item.subject_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="试卷地区:"
                        label-width="110px"
                        prop="dis_id">
            <el-select v-model="form.dis_id"
                       clearable
                       filterable
                       style="width:100%"
                       @change="getSourceList(0)"
                       placeholder="请选择">
              <el-option v-for="item in disList"
                         :key="item.paper_district_id"
                         :label="item.paper_district_name"
                         :value="item.paper_district_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="年级:"
                        prop="grade_id">
            <el-select v-model="form.grade_id"
                       style="width:100%"
                       clearable
                       @change="getSourceList(0)"
                       placeholder="请选择">
              <el-option v-for="item in gradeList"
                         :key="item.grade_id"
                         :label="item.grade_name"
                         :value="item.grade_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="试卷来源:"
                        label-width="110px"
                        prop="paperSource">
            <el-select v-model="form.paperSource"
                       filterable
                       style="width:100%"
                       placeholder="请选择">
              <el-option v-for="item in paperSourceList"
                         :key="item.source_id"
                         :label="item.source_id+ '-' + item.source_name"
                         :value="item.source_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="题目年份:"
                        label-width="110px"
                        prop="paperYear">
            <el-date-picker v-model="form.paperYear"
                            type="year"
                            style="width:100%"
                            value-format="yyyy"
                            placeholder="选择年份">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-button type="primary"
                     @click="getInfo">
            提交
          </el-button>
        </el-col>
      </el-row>
    </el-form>
    <div class="note"
         v-if="info.length">
      <div class="note_title">试卷备注:</div>
      <div class="note_text"><span>{{note?note:"无"}}</span>
        <a class="note_icon"
           alt="修改"
           title="修改"
           @click="showInput">
          <i class="el-icon-edit-outline"></i>
        </a>
      </div>
    </div>
    <p class="paper_sum"
       v-if="info.length">试卷总分: {{nums}}</p>
    <topic ref="topicRef"
           style="margin-right:150px"
           :item="item"
           :index="index"
           v-for="(item,index) in info"
           :key="index" />

    <el-row :gutter="10"
            class="btn"
            v-if="paperChecked == 0 && info.length">
      <el-button type="primary"
                 @click="over">校对完成</el-button>
    </el-row>
    <el-row v-else-if="paperChecked == 1 && info.length"
            style="text-align:center">
      <div class="text_btn_primary">
        试卷已校对
      </div>
    </el-row>

    <scrollTo ref="scrollToRef"
              v-if="info.length"
              :list="childredList" />
    <Remark ref="RemarkRef" />
  </div>
</template>

<script>
import opertion from './opertion/index'
import property from './property/index'
import topic from './components/index.vue'
import scrollTo from '@/components/scrollTo'
import Remark from '../examinationEntry/components/remark.vue'

export default {
  name: 'examinationEntryProofread',
  mixins: [property, opertion],
  components: {
    topic, scrollTo, Remark
  }
}
</script>

<style lang="scss" scoped>
.content_warp {
  display: flex;
  .topic_warp {
    flex: 1;
  }
  .right_scroll_warp {
    width: 100px;
  }
}
.form_warp {
  padding-top: 10px;
  background: white;
}
.btn {
  text-align: center;
  margin-top: 10px;
  padding-bottom: 30px;
}
.paper_sum {
  font-size: 18px;
  font-weight: bold;
  line-height: 32px;
}
.note {
  display: flex;
  margin-top: 5px;
  font-size: 16px;
  line-height: 33px;
  .note_title {
    width: 74px;
  }
  .note_text {
    max-width: calc(100% - 220px);
  }
  .note_icon {
    padding: 4px 8px;
    color: #007dff;
    cursor: pointer;
    font-size: 20px;
    vertical-align: -0.1em;
  }
}
</style>