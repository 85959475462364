export default {
  data () {
    return {
      form: {
        paperYear: '',
        paperSource: '',
        subject_id: '',
        dis_id: '',
        grade_id: '',
      },
      subjectList: [],
      sourceList: [],
      info: [],
      childredList: 0,
      paperChecked: 10,
      rules: {
        subject_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        paperSource: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        paperYear: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
      },
      nums: 0,
      disList: [],
      gradeList: [],
      paperSourceList: [],
      note: ''
    }
  }
}