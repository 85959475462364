<template>
  <div class="fixe_right_btn"
       style="top: 150px;">
    <div class="yuan1"
         :style="{'background':isActive == index?'#3F9EFF':'#CCCCCC'}"
         @click="toMaodian(index)"
         v-for="(item,index) in list"
         :key="index">{{index + 1}}</div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isActive: 0,
      top: 150,
    }
  },
  props: ['list'],
  beforeDestroy () {
    document.removeEventListener('scroll', this.listenerFunction, true)
  },
  mounted () {
    this.$nextTick(() => {
      document.addEventListener('scroll', this.listenerFunction, true);
    })
  },
  watch: {
    /**
     * 
     * 保存代码 防止后续改
     *     isActive (newVal, oldVal) {
      this.$nextTick(() => {
        var oFixedBtn = document.getElementsByClassName('fixe_right_btn')[0]
        var oBody = document.body.offsetWidth
        if (oBody < 1400) {
          if (this.list > 10) {
            if (newVal > oldVal && newVal > 10) {
              this.top -= 40 * (newVal - oldVal)
              oFixedBtn.style.top = this.top + 'px'
            } else if (newVal < oldVal && newVal > 10) {
              this.top += 40 * (oldVal - newVal)
              oFixedBtn.style.top = this.top + 'px'
            }
          }
        } else {
          if (this.list > 30) {
            if (newVal > oldVal) {
              this.top -= 20 * (newVal - oldVal)
              oFixedBtn.style.top = this.top + 'px'
            } else if (newVal < oldVal) {
              this.top += 20 * (oldVal - newVal)
              oFixedBtn.style.top = this.top + 'px'
            }
          }
        }
      })
    },

     */
    isActive (newVal, oldVal) {
      this.$nextTick(() => {
        var oFixedBtn = document.getElementsByClassName('fixe_right_btn')[0]
        oFixedBtn.style.top = this.top + 'px'

      })
    },
  },
  methods: {
    toMaodian (index) {
      this.isActive = index
      let anchorElement = document.getElementsByClassName('yuan')[index];
      if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: 'smooth' });
      }
    },
    listenerFunction (e) {
      this.$nextTick(() => {
        for (var index = 0; index < this.list; index++) {
          if (!document.getElementsByClassName('yuan')[index]) {
            document.removeEventListener('scroll', this.listenerFunction)
          }
          var getAttr = document.getElementsByClassName('yuan')[index].getBoundingClientRect()
          if (getAttr.top < 200 && getAttr.top > -200) {
            this.isActive = index
          }
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.fixe_right_btn {
  display: flex;
  flex-wrap: wrap;
  width: 140px;
  position: fixed;
  transition: all 0.1s;
  right: 30px;
  max-height: 70vh;
  overflow-y: auto;
  /**
  // 保存代码 防止后续改
    display: flex;
  flex-wrap: wrap;
  width: 100px;
  position: fixed;
  transition: all 0.1s;
  right: 20px;
  */
  .yuan1 {
    width: 40px;
    height: 40px;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #cccccc;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    &:hover {
      background: #3f9eff !important;
    }
  }
}
</style>