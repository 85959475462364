<template>
  <div>
    <el-dialog title="试卷备注"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="500px"
               :before-close="handleClose">
      <el-row :gutter="10">
        <el-col :span="24"
                class="center_col">
          <span>备注:</span>
          <el-input clearable
                    v-if="dialogVisible"
                    placeholder="请输入试卷备注"
                    type="textarea"
                    v-model="form.note"
                    :rows="4" />
        </el-col>
      </el-row>
      <el-row style="text-align:center">
        <el-button type="primary"
                   @click="toChecktest">提交</el-button>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { saveNote } from '@/api/note.js'
export default {
  data () {
    return {
      dialogVisible: false,
      form: {},
      showNote: false
    }
  },
  methods: {
    async toChecktest () {
      let form = JSON.parse(JSON.stringify(this.form))
      await saveNote(form)
      this.handleClose()
      this.$notify({
        title: '提示',
        message: '成功!',
        type: 'success'
      });
      if (this.showNote) {
        this.$parent.getPaperNote()
      }
    },
    handleClose () {
      this.dialogVisible = false

    },
  }
}
</script>

<style lang="scss" scoped>
.center_col {
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    width: 60px;
    margin-right: 10px;
    font-size: 18px;
    font-weight: bold;
  }
}
.el-row {
  &:nth-child(1) {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
}

::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
</style>